import axios from "axios";
import MediaType from "../Interfaces/Media";
import Server from "./Server";

export interface FileMedia {
  id?: number;
  key?: string;
  name: string;
  type: string | MediaType;
  path: string | File;
  thumbPath?: string | File;
}

const uploadCsv = (file: File, eventId: string): Promise<string[]> => {
  const shortEventId = eventId.split("/")[2];
  return new Promise((resolve, reject) => {
    Server.get(
      `/s3SignedUrl?filename=event&mime=${encodeURIComponent(file.type)}`
    )
      .then((response: any) => {
        const formData = new FormData();
        formData.append("file", file);
        Server.put(response.url, formData)
          .then(() => {
            Server.post(
              `/invitation/event/${shortEventId}/csvupload?keyname=${encodeURIComponent(
                response.key
              )}`
            )
              .then((res: any) => {
                resolve(res.contactNumbers);
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const UploadMedia = (media: FileMedia): Promise<FileMedia> => {
  return new Promise((resolveUpload, rejectUpload) => {
    if (typeof media.path !== "string") {
      const mimeType = media?.path?.type;
      let key = "";
      console.log(media, media?.path?.type);

      Server.get(`/s3SignedUrl?filename=${media.name}&mime=${mimeType}`)
        .then((response: any) => {
          const signedUrl = response.url;
          key = response.key;
          const options = {
            headers: {
              "Content-Type": mimeType,
            },
          };
          axios
            .put(signedUrl, media.path, options)
            .then(() => {
              resolveUpload({ ...media, key });
            })
            .catch((e) => {
              rejectUpload(e);
            });
        })
        .catch((e) => {
          rejectUpload(e);
        });
    } else {
      resolveUpload({ ...media });
    }
  });
};

export { uploadCsv, UploadMedia };
