import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

import Loader from "../Loader";
import SentInvitation from "../SentInvitations";

import { BulkBookingEventQuery } from "../../Query/Invitations";

import { popoverAlert } from "../../utils/Alert";

import { BulkInvitation } from "../../Interfaces/Invitations";

import AddIcon from "../../images/add_new_plus_icon.png";
import InfoIcon from "../../images/information_icon.png";
import DeleteIcon from "../../images/delete_dark_icon@2x.png";

import * as SendInvitationStyle from "./SendInvitation.module.scss";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { uploadCsv } from "../../utils/UploadFile";
import { AxiosError } from "axios";
import Server from "../../utils/Server";
import Logger from "../../utils/Logger";

interface Props {
  id: string;
}

const Index = ({ id }: Props) => {
  const [contactNumbers, setContactNumber] = useState<string[]>([]);

  const [isInvitationSent, setIsInvitationSent] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { data, error, loading } = useQuery<{
    bulkBookingEvent: BulkInvitation;
  }>(BulkBookingEventQuery, {
    variables: {
      id: `/bulk_booking_events/${id}`,
    },
  });

  useEffect(() => {
    if (error) {
      Logger.showError(error);
    }
  }, [error]);

  const instructions = [
    "Select Event Date",
    "Upload phone numner of the invitees.",
    "Check the event to see numbers have confirmed participation by booking a parking space.",
  ];

  const sendInvitations = () => {
    Swal.fire({
      showCancelButton: true,
      text: "Once You Continue You cannot add more contact numbers",
      type: "warning",
    }).then((e) => {
      if (e.value) {
        const shortEventId = data?.bulkBookingEvent.id.split("/")[2];

        if (!contactNumbers.length) {
          return popoverAlert({
            msg: "Enter Atleast 1 Contact Number",
            icon: "warning",
          });
        }

        setIsLoading(true);

        Server.post(`/event/${shortEventId}/bulkbooking`, {
          contactNumber: contactNumbers,
        })
          .then((response) => {
            Swal.fire({
              type: "success",
              title: "SUCCESS",
            }).then((e) => {
              if (e.value) {
                setIsInvitationSent(true);
              }
            });
          })
          .catch((error: AxiosError) => {
            Logger.showError(error);
          })
          .finally(() => setIsLoading(false));
      }
    });
  };

  return (
    <>
      {data?.bulkBookingEvent.status.code === "APPROVED" && !isInvitationSent && (
        <div className={SendInvitationStyle.container}>
          <h5 className="boldText color-primary textLg">
            {data?.bulkBookingEvent.parkingArea.name}
          </h5>

          <div className={SendInvitationStyle.invitationDetails}>
            <div className={SendInvitationStyle.instrunctions}>
              {instructions.map((text, i) => (
                <p key={text}>
                  {i + 1}.{text}
                </p>
              ))}
            </div>
            <div className={SendInvitationStyle.action}>
              <button onClick={sendInvitations}>
                Send Invitation List By SMS
              </button>
              <button>
                <img src={InfoIcon} alt="info" />
              </button>
            </div>
          </div>

          <div className={SendInvitationStyle.num}>
            <div className={SendInvitationStyle.num__header}>
              <h5 className="boldText color-primary textLg">Guest List</h5>
              <div className={SendInvitationStyle.num__header__action}>
                <label htmlFor="csvfile">
                  <FontAwesomeIcon
                    className="color-primary"
                    size="2x"
                    icon={faUpload}
                  />
                </label>
                {data?.bulkBookingEvent.id && (
                  <input
                    type="file"
                    id="csvfile"
                    hidden
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => {
                      if (e.target.files) {
                        setIsLoading(true);
                        uploadCsv(e.target.files[0], data?.bulkBookingEvent.id)
                          .then((res) => {
                            setContactNumber([...contactNumbers, ...res]);
                          })
                          .catch((err: AxiosError) => {
                            popoverAlert({
                              msg: err.response?.data.detail,
                              icon: "error",
                            });
                          })
                          .finally(() => setIsLoading(false));
                      }
                    }}
                  />
                )}

                <button>
                  <img src={InfoIcon} alt="info" width={16} height={16} />
                </button>
              </div>
            </div>
            <div className={SendInvitationStyle.num__content}>
              <button
                onClick={() => {
                  if (
                    data?.bulkBookingEvent.maxStrength &&
                    parseInt(data?.bulkBookingEvent.maxStrength) >
                      contactNumbers?.length
                  ) {
                    setContactNumber([...contactNumbers, ""]);
                  } else {
                    popoverAlert({
                      msg: "You Have Already Added Maximum Peoples",
                      icon: "warning",
                    });
                  }
                }}
                className={SendInvitationStyle.num__content__add}
              >
                <span>Enter Mobile Number Manually</span>
                <img src={AddIcon} alt="add number" width={20} height={20} />
              </button>
              <div className={SendInvitationStyle.num__content__inputs}>
                {contactNumbers.map((no, index) => (
                  <div
                    key={`${index}-invite`}
                    className={SendInvitationStyle.input}
                  >
                    <input
                      placeholder="Enter Contact Number"
                      onChange={(e) => {
                        setContactNumber(
                          contactNumbers.map((cn, i) =>
                            index === i ? e.target.value : cn
                          )
                        );
                      }}
                      type="number"
                      value={no || ""}
                    />
                    <button
                      onClick={() => {
                        setContactNumber(
                          contactNumbers.filter((_, i) => i !== index)
                        );
                      }}
                    >
                      <img src={DeleteIcon} alt="Remove" width={32} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {(data?.bulkBookingEvent.status.code === "SENT" || isInvitationSent) && (
        <div className={SendInvitationStyle.container}>
          <h5 className="boldText color-primary textLg">
            {data?.bulkBookingEvent.title}
          </h5>
          {data?.bulkBookingEvent && (
            <SentInvitation eventId={data?.bulkBookingEvent.id} />
          )}
        </div>
      )}

      <Loader isLoading={loading || isLoading} />
    </>
  );
};

export default Index;
