import React from "react";

import Layout from "../../Components/Layout";
import SendInvitation from "../../Components/SendInvitation";

const SendInvitationPage = ({ id }) => {
  return (
    <Layout isPrivate showHeader>
      <SendInvitation id={id} />
    </Layout>
  );
};

export default SendInvitationPage;
