import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import Loader from "../Loader";

import { GetSentBulkInvitations } from "../../Query/Invitations";

import {
  GetSendBulkInvitation,
  GetSendBulkInvitationQuery,
} from "../../Interfaces/Invitations";
import moment from "moment";
import Logger from "../../utils/Logger";

import * as SentInvitationsStyle from "./SentInvitations.module.scss";

interface Props {
  eventId: string;
}

const Index = ({ eventId }: Props) => {
  const [sentInvitations, setSentInvitation] =
    useState<GetSendBulkInvitation[]>();
  const [page, setPage] = useState(1);

  const { data, loading, error, refetch, fetchMore } =
    useQuery<GetSendBulkInvitationQuery>(GetSentBulkInvitations, {
      variables: {
        event: eventId,
        order: [{ createdAt: "DESC" }],
      },
    });

  useEffect(() => {
    if (error) {
      Logger.showError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.bulkBookings.collection) {
      setSentInvitation(data.bulkBookings.collection);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <div className={SentInvitationsStyle.container}>
        {sentInvitations?.map(
          ({
            id,
            userName,
            regNo,
            contactNo,
            event: { startDate, endDate },
          }) => (
            <div key={id}>
              <div className={SentInvitationsStyle.card__content}>
                <div className={SentInvitationsStyle.card__content__item}>
                  <h6>Contact Number</h6>
                  <span>{contactNo}</span>
                </div>
                <div className={SentInvitationsStyle.card__content__item}>
                  <h6>Name</h6>
                  <span>{userName || "N/A"}</span>
                </div>
                <div className={SentInvitationsStyle.card__content__item}>
                  <h6>Reg No.</h6>
                  <span>{regNo || "N/A"}</span>
                </div>
                <div className={SentInvitationsStyle.card__content__item}>
                  <h6>Parking From</h6>
                  <span>{moment(startDate).format("DD/MM/YYYY hh:mm")}</span>
                </div>
                <div className={SentInvitationsStyle.card__content__item}>
                  <h6>Parking Till</h6>
                  <span>{moment(endDate).format("DD/MM/YYYY hh:mm")}</span>
                </div>
              </div>
            </div>
          )
        )}
        {data?.bulkBookings &&
          data.bulkBookings.paginationInfo.lastPage > page && (
            <div className={SentInvitationsStyle.loadMore}>
              <button
                onClick={() => {
                  fetchMore({
                    variables: {
                      page: page + 1,
                    },
                  })
                    .then((res) => {
                      setPage(page + 1);
                      setSentInvitation(
                        sentInvitations
                          ? [
                              ...sentInvitations,
                              ...res.data.bulkBookings.collection,
                            ]
                          : res.data.bulkBookings.collection
                      );
                    })
                    .catch((err) => {
                      Logger.showError(err);
                    });
                }}
              >
                Load More
              </button>
            </div>
          )}
      </div>
      <Loader isLoading={loading} />
    </>
  );
};

export default Index;
