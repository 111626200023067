// extracted by mini-css-extract-plugin
export var container = "SendInvitation-module--container--p0XG4";
export var invitationDetails = "SendInvitation-module--invitationDetails--4-KbA";
export var instrunctions = "SendInvitation-module--instrunctions--Uw6ky";
export var action = "SendInvitation-module--action--F-lDy";
export var event = "SendInvitation-module--event--IUa6d";
export var event__content = "SendInvitation-module--event__content--pbdXg";
export var event__content__item = "SendInvitation-module--event__content__item--gKuys";
export var num = "SendInvitation-module--num--5q8KG";
export var num__header = "SendInvitation-module--num__header--PWnVa";
export var num__header__action = "SendInvitation-module--num__header__action--+R2MY";
export var num__content = "SendInvitation-module--num__content--JkADR";
export var num__content__add = "SendInvitation-module--num__content__add--muC0x";
export var num__content__inputs = "SendInvitation-module--num__content__inputs--wOHYi";
export var input = "SendInvitation-module--input--YZOj7";